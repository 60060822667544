export default {
    avatar: {
      size: {
        large: 'px-4 py-2 text-sm h-64 w-64',
      },
    },
    modal: {
      base:
        'lg:w-2/5 lg:h-96  px-6 py-4 overflow-hidden bg-white rounded-lg dark:bg-gray-800 sm:w-full',
    },
  }